import fxdxlogo from "../../img/fxdx-logo.svg";
import { SiTwitter, SiDiscord } from "react-icons/si";
import { FaTelegramPlane } from "react-icons/fa";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import "./Header.css";
import { useState , useEffect } from "react";
import axios from 'axios';

export function Header() {
  const [priceData, setPriceData] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      axios
        .get("https://token.fxdx.exchange/tokendata/")
        .then((res) => setPriceData(res.data.fxdxPrice))
        .catch(err => {console.log(err.message)});
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const [smallOpen, setsmallOpen] = useState(false);

  return (
    <div className="header">
      <div className="header-left">
        <span className="menu-button" onClick={() => setsmallOpen(!smallOpen)}>
          <AiOutlineMenu size={24} />
        </span>
        <div className="logo">
          <img src={fxdxlogo} alt="fxdx-logo" />
        </div>

        <div className="page-links">
          <p className="active">Home</p>
          <a target="_blank" rel="noreferrer" href="https://trade.fxdx.exchange/">
            Mainnet v2
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://fxdxdocs.gitbook.io/fxdx-knowledge-base/"
          >
            Docs
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://fxdxdex.medium.com/"
          >
            Blog
          </a>
        </div>
      </div>

      <div className="header-right">
        {/* <a
          className="socials"
          target="_blank"
          rel="noreferrer"
          href="https://twitter.com/fxdxdex"
        >
          <SiTwitter color="white" />
        </a>
        <a
          className="socials"
          target="_blank"
          rel="noreferrer"
          href="https://t.me/fxdxdex"
        >
          <FaTelegramPlane color="white" />
        </a>
        <a
          className="socials"
          target="_blank"
          rel="noreferrer"
          href="https://discord.gg/AdmFvVjfjh"
        >
          <SiDiscord color="white" />
        </a> */}

        {/* <div className="settings-icon">
                    <IoMdSettings />
                </div> */}
        <div className="header-right">
        <div className="priceFeed">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.mexc.com/exchange/FXDX_USDT"
            style={{ color: "white" }}
          >
            <div className="priceComponent">
              <div className="fxdxLogo">
                <svg
                  width="23"
                  height="23"
                  viewBox="0 0 23 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="23" height="23" rx="11.5" fill="white" />
                  <path
                    d="M12.9718 14.5424L11.5075 12.5037L10.032 14.5424H8.8493L10.9218 11.6927L8.90561 8.93315H10.0883L11.5075 10.9043L12.9154 8.93315H14.0981L12.0932 11.7153L14.1544 14.5424H12.9718Z"
                    fill="black"
                  />
                  <path
                    d="M6 5.99854H8.46935V6.95382H7.02522V16.0467H8.46935V17.002H6V5.99854Z"
                    fill="black"
                  />
                  <path
                    d="M14.5341 17.002V16.0467H15.9672V6.95382H14.5341V5.99854H17.0035V17.002H14.5341Z"
                    fill="black"
                  />
                </svg>
              </div>
              
              {priceData !== '' ? (
                <>
                  <div className="priceData">{priceData}</div>
                  <div className="buyBtn">
                    <div className="buy">Buy</div>
                  </div>
                </>
               ) : (
                <div className="loader shimmer"></div>
              )}
            </div>
          </a>
        </div>

        <div className="launch-button">
          <a target="_blank" rel="noreferrer" href="https://trade.fxdx.exchange">
            Launch v2 Alpha
          </a>

          <a target="_blank" rel="noreferrer" href="https://trade.fxdx.exchange">
            Launch v2 Alpha
          </a>
        </div>
      </div>   
       </div>
      {smallOpen && (
        <div
          className="header-small"
          style={{ transform: "translateX(0%) translateZ(0px)" }}
        >
          <div className="logo">
            <img src={fxdxlogo} alt="fxdx-logo" />
            <AiOutlineClose
              color="white"
              onClick={() => setsmallOpen(false)}
              size={24}
            />
          </div>

          <div className="page-links">
            <p className="active">Home</p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://trade.fxdx.exchange/"
            >
              Mainnet
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://fxdxdocs.gitbook.io/fxdx-knowledge-base/"
            >
              Docs
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://fxdxdex.medium.com/"
            >
              Blog
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://fxdx.beehiiv.com/"
            >
              Newsletter
            </a>  
          </div>

          <div className="socials-container">
            <a
              className="socials"
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/fxdxdex"
            >
              <SiTwitter color="white" size={24} />
            </a>
            <a
              className="socials"
              target="_blank"
              rel="noreferrer"
              href="https://t.me/fxdxdex"
            >
              <FaTelegramPlane color="white" size={24} />
            </a>
            <a
              className="socials"
              target="_blank"
              rel="noreferrer"
              href="https://discord.gg/AdmFvVjfjh"
            >
              <SiDiscord color="white" size={24} />
            </a>
          </div>
        </div>
      )}
    </div>
    
  );
}
